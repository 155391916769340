import React from 'react'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'
import styles from 'components/common/Loader/styles.module.css'

export interface Props {
  size?: number
  className?: string
}

export const Loader: React.FC<Props> = React.memo(({ size, className }) => (
  <LoadingOutlined size={size} className={classNames(styles.wrapper, className)} />
))
