import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSourceReference } from 'redux/actions/sourcereference'
import { getSourceReferenceItems } from 'redux/selectors/sourcereference'
import { DataTable } from 'components/common/Table/DataTable'
import { Card } from 'components/common/Card'
import { getOpenedModalId } from 'redux/selectors/modals'
import { SourceReferenceModal } from 'components/SourceReference/SourceReferenceModal'
import {
  SOURCE_REFERENCE_TABLE_COLUMNS,
  NON_SORTABLE_COLUMNS,
} from 'components/SourceReference/SourceReferenceTable/constants'
import { getIsLoading } from 'redux/selectors/loaders'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { CreateSourceReferenceBtn } from 'components/SourceReference/CreateSourceReferenceBtn'

const SourceReferenceView: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSourceReference())
  }, [])
  const sourceReferenceData = useSelector(getSourceReferenceItems)
  const isLoading = useSelector<RootState, boolean>((state) =>
    getIsLoading(state, Loaders.FetchSoureReference)
  )
  const openedModalId = useSelector(getOpenedModalId)
  return (
    <>
      <SourceReferenceModal openedModalId={openedModalId} />
      <Card isLoading={isLoading}>
        <CreateSourceReferenceBtn />
        <DataTable
          data={sourceReferenceData}
          dataName="sourcereference"
          columns={SOURCE_REFERENCE_TABLE_COLUMNS}
          nonSortableColumns={NON_SORTABLE_COLUMNS}
        />
      </Card>
    </>
  )
}

export default SourceReferenceView
