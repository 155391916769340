import React, { useCallback, useState, useEffect } from 'react'
import { FormInstance } from 'antd/es/form'
import { useIntl } from 'react-intl'
import { Modal, Form, message } from 'antd'
import { ModalsType } from 'redux/reducers/modals/types'
import { Footer } from 'components/modals/ModalsContainer/Footer'
import styles from './styles.module.css'

interface Props {
  openedModalId: ModalsType | null
  isLoading: boolean
  onCloseModal: () => void
  onSubmitClick: (formValues: any) => void
  handleOk: () => void
  renderModalContent: () => JSX.Element | null
  form: FormInstance
}

export const ModalContainer: React.FC<Props> = ({
  openedModalId,
  isLoading,
  onCloseModal,
  onSubmitClick,
  handleOk,
  renderModalContent,
  form,
}) => {
  const { formatMessage } = useIntl()

  const title = formatMessage({
    id: `component.modal.title.${openedModalId || ModalsType.NewSourceReference}`,
  })
  const cancelText = formatMessage({ id: 'component.modal.footer.button.cancel' })
  const okText = formatMessage({ id: 'component.modal.footer.button.submit' })
  const handleCancel = useCallback(() => {
    message.info(formatMessage({ id: 'component.modal.confirmation.message' }))
  }, [])

  const getReviseFormValues = (formValues: any) => {
    return Object.entries(formValues).reduce<Record<any, any>>((revisedValues, [key, value]) => {
      revisedValues[key] = value === undefined ? null : value
      return revisedValues
    }, {})
  }

  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields()
      const revisedValues: any = getReviseFormValues(form.getFieldsValue())

      onSubmitClick(revisedValues)
      const confirmationContent = (
        <div>
          <p>{formatMessage({ id: 'component.modal.confirmation.body' })}</p>
        </div>
      )
      Modal.confirm({
        title: formatMessage({ id: 'component.modal.confirmation.title' }),
        content: confirmationContent,
        onOk: handleOk,
        onCancel: handleCancel,
      })
    } catch (err: any) {
      console.log(formatMessage({ id: 'component.modal.validation.error' }))
    }
  }, [openedModalId])

  return (
    <Modal
      destroyOnClose
      open={!!openedModalId}
      className={styles.modal}
      title={title}
      onCancel={onCloseModal}
      width={900}
      cancelText={cancelText}
      okText={okText}
      footer={
        <Footer
          handleCloseModal={onCloseModal}
          isLoading={isLoading}
          openedModalId={openedModalId}
          handleSubmit={handleSubmit}
        />
      }
    >
      {renderModalContent()}
    </Modal>
  )
}
