import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { Select, Tooltip } from 'antd'
import styles from './styles.module.css'

const { Option } = Select

export interface Props {
  brands: string[]
  isLoading: boolean
  selectedBrands: string[]
  onChange: (values: string[]) => void
  className?: string
}

export const BrandSelect: React.FC<Props> = ({
  brands,
  selectedBrands,
  isLoading,
  onChange,
  className,
}) => {
  const { formatMessage } = useIntl()
  const renderOption = useCallback(
    (brand: string, index: number) => (
      <Option key={index} value={brand}>
        {brand}
      </Option>
    ),
    []
  )

  return (
    <Tooltip title={formatMessage({ id: 'component.filter.toolTip.brandsSelect' })}>
      <div className={classNames(styles.wrapper, className)}>
        <Select
          allowClear={true}
          mode="multiple"
          value={selectedBrands}
          disabled={isLoading}
          placeholder="brands"
          className={styles.select}
          onChange={onChange}
        >
          {brands && brands.map(renderOption)}
        </Select>
      </div>
    </Tooltip>
  )
}
