import { Rule } from 'antd/es/form'
import { SourceReferenceDataColumnsIds } from 'components/SourceReference/SourceReferenceTable/types'
import { Gutter } from 'antd/es/grid/row'
import { KeyboardEvent } from 'react'
import { ModalsType } from 'redux/reducers/modals/types'
import { allowPositiveOnlyValues } from 'utils/input'
import { ValidationMessageType } from 'components/constants'

export enum FormItemType {
  INPUT = 'input',
}

export type mappedFormItemType = {
  value: string
  label: string
}

export type formConfig = {
  name?: string
  label?: string
  rules: Rule[]
}

export type SourceReferenceFormItemType = {
  id: SourceReferenceDataColumnsIds
  type: FormItemType
  config?: formConfig
  col: Record<'offset' | 'span', number>
  autosize?: boolean | false
  allowClear?: boolean | false
  showSearch?: boolean | false
  onChange?: (event: KeyboardEvent<HTMLInputElement>) => void | undefined
  mode?: 'multiple'
  disabled?: boolean
  excludedFrom?: ModalsType[]
}

export type FormRawItemType = {
  id: number
  config: Record<string, Gutter | [Gutter, Gutter]>
  items: SourceReferenceFormItemType[]
}

export const FormRawItemsConfig: FormRawItemType[] = [
  {
    id: 1,
    config: {
      gutter: 10,
    },
    items: [
      {
        id: SourceReferenceDataColumnsIds.ID,
        type: FormItemType.INPUT,
        col: { span: 12, offset: 0 },
        config: { rules: [{ required: true, message: ValidationMessageType.UNIQUEIDS }] },

        onChange: allowPositiveOnlyValues,
      },
    ],
  },
  {
    id: 2,
    config: {
      gutter: 10,
    },
    items: [
      {
        id: SourceReferenceDataColumnsIds.SourceName,
        type: FormItemType.INPUT,
        col: { span: 12, offset: 0 },
        config: {
          rules: [{ required: true, message: ValidationMessageType.NONEMPTY }],
        },
      },
      {
        id: SourceReferenceDataColumnsIds.SupplierName,
        type: FormItemType.INPUT,
        col: { span: 12, offset: 0 },
        config: { rules: [{ required: true, message: ValidationMessageType.NONEMPTY }] },
      },
    ],
  },
]
