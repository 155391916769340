import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { FilterStateType } from 'redux/reducers/filters/types'
import { ConsumeFiltersPayLoad } from 'redux/actions/filters/types'
import { FiltersInitialState } from 'redux/reducers/filters/constants'

export const filters = createSlice({
  name: ReducersType.Filters,
  initialState: FiltersInitialState,
  reducers: {
    consumeFilters: (
      state: FilterStateType,
      { payload: { filters } }: PayloadAction<ConsumeFiltersPayLoad>
    ) => ({
      ...state,
      ...filters,
    }),
  },
})
