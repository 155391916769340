import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { Loaders } from 'redux/reducers/loaders/types'
import { startLoading, stopLoading } from 'redux/actions/loaders/'
import {
  PermissionItem,
  EditedPermissionItem,
  BasePermissionItem,
} from 'redux/reducers/permissions/types'
import api from 'api'
import { consumePermissions, fetchPermissions } from 'redux/actions/permissions'
import { handleError } from 'redux/actions/errors'
import {
  FETCH_PERMISSIONS,
  PATCH_PERMISSION,
  ADD_PERMISSION,
} from 'redux/actions/permissions/constants'
import { closeModal } from 'redux/actions/modals'
import {
  getEditPermissionData,
  getEditPermissionId,
  getNewPermissionData,
} from 'redux/selectors/permissions'

function* handleFetchPermissions() {
  yield put(startLoading(Loaders.FetchPermissions))

  try {
    const data: PermissionItem[] = yield call(api.getPeremissions)

    if (data) {
      yield put(consumePermissions({ items: data }))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.FetchPermissions))
  }
}

function* handlePatchPermission(): Generator<unknown, any, EditedPermissionItem> {
  yield put(startLoading(Loaders.PatchPermission))
  const editPermissionData = yield select(getEditPermissionData)
  const oldPermissionId = yield select(getEditPermissionId)

  try {
    yield call(api.patchPermission, {
      body: { ...editPermissionData, oldPermissionId },
    })
    yield put(fetchPermissions())
    yield put(closeModal())
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.PatchPermission))
  }
}

function* handleAddPermission(): Generator<unknown, any, BasePermissionItem> {
  yield put(startLoading(Loaders.NewPermission))
  const addPermissionData = yield select(getNewPermissionData)

  try {
    yield call(api.addPermission, {
      body: addPermissionData,
    })
    yield put(fetchPermissions())
    yield put(closeModal())
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.NewPermission))
  }
}

export function* permissions() {
  yield all([
    takeLatest(FETCH_PERMISSIONS, handleFetchPermissions),

    takeLatest(PATCH_PERMISSION, handlePatchPermission),

    takeLatest(ADD_PERMISSION, handleAddPermission),
  ])
}
