export const config = {
  dev: {
    api: {
      url: 'http://localhost:8000',
    },
    auth: {
      prefix: '@@auth0spajs@@::',
      domain: 'newsuk-agw-uat.eu.auth0.com',
      clientID: 'PUYmkErB0I9ZqdqKnTTj4DvozQoaPkAl',
      audience: 'https://api.news.co.uk/permissions-center',
      redirectUri: 'http://localhost:8081?authCallBack=true',
      logoutUri: 'http://localhost:8081/login',
      responseType: 'token id_token',
      scope: 'openid profile email offline_access',
    },
  },
  uat: {
    api: {
      url: 'https://permission-centre-api.uat-news.co.uk/',
    },
    auth: {
      prefix: '@@auth0spajs@@::',
      domain: 'newsuk-agw-uat.eu.auth0.com',
      clientID: 'PUYmkErB0I9ZqdqKnTTj4DvozQoaPkAl',
      audience: 'https://api.news.co.uk/permissions-center',
      redirectUri: 'https://permission-centre-admin.uat-news.co.uk/?authCallBack=true',
      logoutUri: 'https://permission-centre-admin.uat-news.co.uk/login',
      responseType: 'token id_token',
      scope: 'openid profile email offline_access',
    },
  },
  staging: {
    api: {
      url: 'https://permission-centre-api.staging-news.co.uk/',
    },
    auth: {
      prefix: '@@auth0spajs@@::',
      domain: 'newsuk-agw-staging.eu.auth0.com',
      clientID: 'FhnjAeXxBTrlIjdq3PYGKbXRkVGpGp3q',
      audience: 'https://api.news.co.uk/permissions-center',
      redirectUri: 'https://permission-centre-admin.staging-news.co.uk/?authCallBack=true',
      logoutUri: 'https://permission-centre-admin.staging-news.co.uk/login',
      responseType: 'token id_token',
      scope: 'openid profile email offline_access',
    },
  },
  prod: {
    api: {
      url: 'https://permission-centre-api.news.co.uk/',
    },
    auth: {
      prefix: '@@auth0spajs@@::',
      domain: 'newsuk-agw.eu.auth0.com',
      clientID: 'wbbS3IvJ4t53rxv8puMHzRZon7YXzVaC',
      audience: 'https://api.news.co.uk/permissions-center',
      redirectUri: 'https://permission-centre-admin.news.co.uk/?authCallBack=true',
      logoutUri: 'https://permission-centre-admin.news.co.uk/login',
      responseType: 'token id_token',
      scope: 'openid profile email offline_access',
    },
  },
}
