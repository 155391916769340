const messages = {
  'component.modal.title.editPermission': 'Edit Permission',
  'component.modal.title.viewPermission': 'Preview Permission',
  'component.modal.title.newPermission': 'Add New Permission',
  'component.modal.title.newSourceReference': 'Add New Source Reference',
  'component.modal.confirmation.title': 'Confirm Submission',
  'component.modal.confirmation.body': 'Are you sure you want to submit the changes',
  'component.modal.confirmation.message': 'Submission cancelled',
  'component.modal.footer.button.cancel': 'Cancel',
  'component.modal.footer.button.submit': 'Submit',
  'component.modal.validation.error': 'Could not validate form data',
}

export default messages
