import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'routes/constants'
import styles from './styles.module.css'

export interface Props {
  previousRoute?: string
}

export const Login: React.FC<Props> = ({ previousRoute = Routes.Index }) => {
  const { loginWithPopup, isAuthenticated } = useAuth0()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      return navigate(previousRoute)
    }

    loginWithPopup()
  }, [isAuthenticated])

  return <div className={styles.wrapper}>Permission Center</div>
}
