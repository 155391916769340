import { SortDirection } from 'react-virtualized'
import { SortersStateType } from 'redux/reducers/sorters/types'
import { PermissionDataColumnsIds } from 'components/Permissions/PermissionsTable/types'

export const SortInitialState: SortersStateType = {
  sorter: {
    sortBy: PermissionDataColumnsIds.ID,
    sortDirection: SortDirection.ASC,
  },
}
