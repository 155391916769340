import React from 'react'
import { Button } from 'antd'
import { useIntl } from 'react-intl'
import { ModalsType } from 'redux/reducers/modals/types'

type Props = {
  handleCloseModal: () => void
  isLoading?: boolean
  openedModalId: ModalsType | null
  handleSubmit: () => void
  disabled?: boolean
}

export const Footer: React.FC<Props> = ({
  handleCloseModal,
  isLoading,
  openedModalId,
  handleSubmit,
  disabled,
}) => {
  const { formatMessage } = useIntl()
  const RenderSubmit =
    openedModalId == ModalsType.ViewPermission ? null : (
      <Button
        key="submit"
        type="primary"
        loading={isLoading}
        onClick={handleSubmit}
        disabled={disabled}
      >
        {formatMessage({ id: 'component.modal.footer.button.submit' })}
      </Button>
    )

  return (
    <>
      <Button key="cancel" onClick={handleCloseModal}>
        {formatMessage({ id: 'component.modal.footer.button.cancel' })}
      </Button>
      {RenderSubmit}
    </>
  )
}
