import React, { useCallback } from 'react'
import classNames from 'classnames'
import { Input, Tooltip } from 'antd'
import styles from './styles.module.css'

export interface Props {
  value: string
  placeholder?: string
  autoFocus?: boolean
  className?: any
  onSearchTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClearTextClick: () => void
  onSearchSubmit?: () => void
  tooltipTitle?: string
}

export const SearchBar: React.FC<Props> = ({
  value,
  placeholder,
  onSearchTextChange,
  className,
  tooltipTitle,
}) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      return onSearchTextChange(e)
    },
    [onSearchTextChange]
  )

  return (
    <Tooltip title={tooltipTitle}>
      <Input
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className={classNames(styles.input, className)}
        allowClear
      />
    </Tooltip>
  )
}
