import { KeyboardEvent } from 'react'
import config from 'config'

export const allowPositiveOnlyValues = (event: KeyboardEvent<HTMLInputElement>) => {
  if (!/([0-9\b]|Backspace|Arrow|Delete)/.test(event.key)) {
    event.preventDefault()
  }
}

export const validateJSON = (_: any, value: string): Promise<void> => {
  if (!value) return Promise.reject()

  try {
    return Promise.resolve(JSON.parse(value))
  } catch (error: any) {
    return Promise.reject(error.message)
  }
}

export const isProduction = (): boolean => {
  return config.env === 'prod'
}

export const validateUUIDV4 = (_: any, value: string) => {
  if (!value) return Promise.reject()
  try {
    if (/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(value)) {
      return Promise.resolve(value)
    } else {
      return Promise.reject()
    }
  } catch (error: any) {
    return Promise.reject(error.message)
  }
}
