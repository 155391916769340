import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { SortersStateType } from 'redux/reducers/sorters/types'
import { SetSortersType } from 'redux/actions/sorters/types'
import { SortInitialState } from 'redux/reducers/sorters/constants'

export const sorters = createSlice({
  name: ReducersType.Sorters,
  initialState: SortInitialState,
  reducers: {
    setSorters: (
      state: SortersStateType,
      { payload: { sortBy, sortDirection } }: PayloadAction<SetSortersType>
    ) => ({
      ...state,
      sorter: { sortBy, sortDirection },
    }),
  },
})
