export enum PermissionDataColumnsIds {
  ID = 'id',
  PermissionId = 'permissionId',
  DisplayName = 'displayName',
  PermisionCode = 'permissionCode',
  Brand = 'brand',
  AllowedChannels = 'channels',
  PermissionDescription = 'description',
  PermissionType = 'type',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ActiveFlag = 'active',
  Edit = 'edit',
  View = 'view',
  Copy = 'copy',
  ParentId = 'parentId',
  DisplaySequence = 'displaySequence',
}
