import orderBy from 'lodash.orderby'
import { RootState } from 'redux/types/store'
import { SortDirection } from 'react-virtualized'
import { createSelector } from 'reselect'
import { getSorters } from 'redux/selectors/sorters'
import { SourceReferenceItem } from 'redux/reducers/sourcereference/types'
import { Sorters } from 'redux/selectors/permissions/index'
import { SOURCE_REFERENCE_TABLE_COLUMNS } from 'components/SourceReference/SourceReferenceTable/constants'

export const getSourceReference = (state: RootState) => state.sourceReference.items

export const getSourceReferenceIds = (state: RootState) =>
  state.sourceReference.items.map(({ id }: SourceReferenceItem) => id)

export const getSourceReferenceItems = createSelector(
  getSourceReference,
  getSorters,
  (sourceReferences: SourceReferenceItem[], sorters: Sorters): SourceReferenceItem[] => {
    const { sortBy, sortDirection } = sorters
    if (!sortBy || !sourceReferences.length) {
      return sourceReferences
    }
    const directionOrderBy = SortDirection.ASC === sortDirection ? 'asc' : 'desc'
    return orderBy(sourceReferences, [sortBy], [directionOrderBy]) as SourceReferenceItem[]
  }
)

export const mappedSourceReferenceFormFields = createSelector(() => {
  return SOURCE_REFERENCE_TABLE_COLUMNS.map(({ dataKey }) => ({ name: [dataKey], value: null }))
})
