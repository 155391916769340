import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { SourceReferenceStateType } from 'redux/reducers/sourcereference/types'
import {
  ConsumeSourceReferencePyaLoad,
  NewSourceReferencePayload,
} from 'redux/actions/sourcereference/types'
import { SourceReferenceInitialState } from 'redux/reducers/sourcereference/constants'

export const sourceReference = createSlice({
  name: ReducersType.SourceReference,
  initialState: SourceReferenceInitialState,
  reducers: {
    consumeSourceReference: (
      state: SourceReferenceStateType,
      { payload: { items } }: PayloadAction<ConsumeSourceReferencePyaLoad>
    ) => ({
      ...state,
      items,
    }),
    setNewSourceReference: (
      state: SourceReferenceStateType,
      { payload: { newSourceReference } }: PayloadAction<NewSourceReferencePayload>
    ) => ({
      ...state,
      newSourceReference,
    }),
    removeNewSourceReference: (state: SourceReferenceStateType) => ({
      ...state,
      newSourceReference: SourceReferenceInitialState.newSourceReference,
    }),
  },
})
