import { objToFormItemMapping } from 'utils/form/index'
import { Rule } from 'antd/es/form'
import { PermissionDataColumnsIds } from 'components/Permissions/PermissionsTable/types'
import { SelectOptionProps } from './SelectItem/index'
import { Gutter } from 'antd/es/grid/row'
import { allowPositiveOnlyValues, validateJSON, isProduction, validateUUIDV4 } from 'utils/input'
import { KeyboardEvent } from 'react'
import { ModalsType } from 'redux/reducers/modals/types'
import { ValidationMessageType } from 'components/constants'

export enum FormItemType {
  TEXTAREA = 'textarea',
  CHECKBOX = 'checkBox',
  SELECT = 'select',
  INPUT = 'input',
}

export type mappedFormItemType = {
  value: string
  label: string
}

export enum PERMISSIONTYPE {
  MARKETING = 'MARKETING',
  PRODUCT = 'PRODUCT',
}

export enum ALLOWEDCHANNELS {
  sms = 'sms',
  email = 'email',
  post = 'post',
  phone = 'phone',
}

export type PermissionFormItemType = {
  id: PermissionDataColumnsIds
  type: FormItemType
  config?: Record<'rules', Rule[]>
  col: Record<'offset' | 'span', number>
  autosize?: boolean | false
  allowClear?: boolean | false
  showSearch?: boolean | false
  onChange?: (event: KeyboardEvent<HTMLInputElement>) => void | undefined
  mode?: 'multiple'
  disabled?: boolean
  excludedFrom?: ModalsType[]
}

export type FormRawItemType = {
  id: number
  config: Record<string, Gutter | [Gutter, Gutter]>
  items: PermissionFormItemType[]
}

export const FormRawItemsConfig: FormRawItemType[] = [
  {
    id: 1,
    config: {
      gutter: 10,
    },
    items: [
      {
        id: PermissionDataColumnsIds.ID,
        type: FormItemType.INPUT,
        col: { span: 12, offset: 0 },
        config: {
          rules: [
            {
              required: true,
              validator: validateUUIDV4,
              message: ValidationMessageType.UUIDV4,
            },
          ],
        },
        excludedFrom: [ModalsType.NewPermission],
        allowClear: true,
      },
      {
        id: PermissionDataColumnsIds.PermissionId,
        type: FormItemType.INPUT,
        col: { span: 12, offset: 0 },
        disabled: isProduction(),
        onChange: allowPositiveOnlyValues,
        config: { rules: [{ required: true, message: ValidationMessageType.UNIQUEIDS }] },
        excludedFrom: [ModalsType.NewPermission],
      },
      {
        id: PermissionDataColumnsIds.DisplayName,
        type: FormItemType.INPUT,
        config: { rules: [{ required: true, message: ValidationMessageType.NONEMPTY }] },
        col: { span: 12, offset: 0 },
      },
    ],
  },
  {
    id: 2,
    config: {
      gutter: [16, 16],
    },
    items: [
      {
        id: PermissionDataColumnsIds.ParentId,
        type: FormItemType.SELECT,
        col: { span: 12, offset: 0 },
        allowClear: true,
      },
      {
        id: PermissionDataColumnsIds.PermisionCode,
        type: FormItemType.INPUT,
        config: { rules: [{ required: true }] },
        col: { span: 12, offset: 0 },
      },
    ],
  },
  {
    id: 3,
    config: {
      gutter: [16, 16],
    },
    items: [
      {
        id: PermissionDataColumnsIds.PermissionDescription,
        type: FormItemType.TEXTAREA,
        config: { rules: [{ required: true }] },
        col: { span: 24, offset: 0 },
      },
    ],
  },
  {
    id: 4,
    config: {
      gutter: [16, 16],
    },
    items: [
      {
        id: PermissionDataColumnsIds.Brand,
        type: FormItemType.SELECT,
        config: { rules: [{ required: true }] },
        col: { span: 4, offset: 0 },
      },
      {
        id: PermissionDataColumnsIds.PermissionType,
        type: FormItemType.SELECT,
        config: { rules: [{ required: true }] },
        col: { span: 4, offset: 0 },
      },
      {
        id: PermissionDataColumnsIds.ActiveFlag,
        type: FormItemType.CHECKBOX,
        config: { rules: [{ required: true }] },
        col: { span: 2, offset: 0 },
      },
      {
        id: PermissionDataColumnsIds.DisplaySequence,
        type: FormItemType.INPUT,
        config: { rules: [{ required: true }] },
        col: { span: 4, offset: 0 },
        onChange: allowPositiveOnlyValues,
      },
      {
        id: PermissionDataColumnsIds.AllowedChannels,
        type: FormItemType.SELECT,
        config: { rules: [{ required: true }] },
        col: { span: 10, offset: 0 },
        mode: 'multiple',
        allowClear: true,
      },
    ],
  },
  {
    id: 5,
    config: {
      gutter: [16, 16],
    },
    items: [
      {
        id: PermissionDataColumnsIds.Copy,
        type: FormItemType.TEXTAREA,
        config: {
          rules: [
            {
              required: true,
              validator: validateJSON,
            },
          ],
        },
        col: { span: 24, offset: 0 },
        autosize: true,
      },
    ],
  },
]

export const mappedPemissionsType: SelectOptionProps[] = objToFormItemMapping(PERMISSIONTYPE)
export const mappedAllowedChannels: SelectOptionProps[] = objToFormItemMapping(ALLOWEDCHANNELS)
