import { TableColumnType } from 'components/common/Table'
import { AllowedChannels } from 'components/Permissions/AllowedChannels'
import { IsActivePermission } from 'components/Permissions/IsActivePermission'
import { EditPermissionButton } from 'components/Permissions/EditPermissionButton'
import { ViewButton } from 'components/Permissions/ViewButton'
import { formatDate } from 'utils/date'
import { PermissionType } from 'components/Permissions/PermissionType'
import { PermissionDataColumnsIds } from './types'
import { TableCellProps } from 'react-virtualized'

export const PERMISSIONS_TABLE_COLUMNS: TableColumnType[] = [
  {
    id: PermissionDataColumnsIds.PermissionId,
    dataKey: PermissionDataColumnsIds.PermissionId,
    width: 100,
  },
  {
    id: PermissionDataColumnsIds.ID,
    dataKey: PermissionDataColumnsIds.ID,
    width: 350,
  },
  {
    id: PermissionDataColumnsIds.DisplayName,
    dataKey: PermissionDataColumnsIds.DisplayName,
  },
  {
    id: PermissionDataColumnsIds.PermisionCode,
    dataKey: PermissionDataColumnsIds.PermisionCode,
  },
  {
    id: PermissionDataColumnsIds.Brand,
    dataKey: PermissionDataColumnsIds.Brand,
    width: 100,
  },
  {
    id: PermissionDataColumnsIds.AllowedChannels,
    dataKey: PermissionDataColumnsIds.AllowedChannels,
    customCellRenderer: (data) => <AllowedChannels allowedChannels={data.cellData} />,
  },
  {
    id: PermissionDataColumnsIds.PermissionDescription,
    dataKey: PermissionDataColumnsIds.PermissionDescription,
  },
  {
    id: PermissionDataColumnsIds.PermissionType,
    dataKey: PermissionDataColumnsIds.PermissionType,
    width: 70,
    customCellRenderer: (data) => <PermissionType label={data.rowData.type} />,
  },
  {
    id: PermissionDataColumnsIds.CreatedAt,
    dataKey: PermissionDataColumnsIds.CreatedAt,
    width: 120,
    customCellRenderer: (data) => formatDate(data.rowData.createdAt),
  },
  {
    id: PermissionDataColumnsIds.UpdatedAt,
    dataKey: PermissionDataColumnsIds.UpdatedAt,
    width: 120,
    customCellRenderer: (data) => formatDate(data.rowData.updatedAt),
  },
  {
    id: PermissionDataColumnsIds.ActiveFlag,
    dataKey: PermissionDataColumnsIds.ActiveFlag,
    width: 75,
    customCellRenderer: (data) => <IsActivePermission isActive={data.cellData} />,
  },
  {
    id: PermissionDataColumnsIds.Edit,
    dataKey: PermissionDataColumnsIds.Edit,
    width: 65,
    customCellRenderer: (data: TableCellProps) => <EditPermissionButton id={data.rowData.id} />,
  },
  {
    id: PermissionDataColumnsIds.View,
    dataKey: PermissionDataColumnsIds.View,
    width: 65,
    customCellRenderer: (data) => <ViewButton id={data.rowData.id} />,
  },
]

export const NON_SORTABLE_COLUMNS = [
  PermissionDataColumnsIds.ID,
  PermissionDataColumnsIds.AllowedChannels,
  PermissionDataColumnsIds.Edit,
  PermissionDataColumnsIds.View,
]
