export enum HTTPHeadersType {
  ContentType = 'Content-Type',
  Authorization = 'Authorization',
}

export enum HttpContentTypeHeaderValues {
  ApplicationJSON = 'application/json',
}

type HTTPContentTypeHeader = Record<HTTPHeadersType.ContentType, HttpContentTypeHeaderValues>
type HTTPAuthorizationHeader = Record<HTTPHeadersType.Authorization, string>

export type HTTPHeaders = HTTPContentTypeHeader | HTTPAuthorizationHeader

export type FetchParams = Record<string, string>
export type FetchBody = Record<string, unknown>

export enum ApiRouteNames {
  GetArticleIndex = 'getArticleIndex',
  GetArticleReferrers = 'getArticleReferrers',
  GetEngagement = 'getEngagement',
  GetPermissions = 'getPeremissions',
  PatchPermission = 'patchPermission',
  AddPermission = 'addPermission',
  GetBrands = 'getBrands',
  GetSourceReferences = 'getSourceReferences',
  AddSourceRefere = 'addSourceReference',
}

export enum ApiRoutePaths {
  ReportArticleIndex = '/report/article-index',
  ReportReferrers = '/report/referrers',
  ReportEngagement = '/report/engagement',
  AdminPermissions = '/admin/permissions',
  AdminEditPermission = '/admin/permission',
  AdminAddPermission = '/admin/addpermission',
  AdminBrands = '/admin/brands',
  AdminSourceReference = '/admin/sourcereference',
  AdminAddSourceReference = '/admin/addsourcereference',
}

export interface FetchArgsType {
  body?: FetchBody
  urlParams?: FetchParams
  queryParams?: FetchParams
}

export interface ReplaceUrlArgsType {
  url: string
  urlParams?: FetchParams
}

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

export interface ApiRoute {
  url: ApiRoutePaths
  method: HTTPMethods
  headers: HTTPHeaders
}

export type ApiRoutesType = Record<string, ApiRoute>

export type WrappedApiCallback = (args?: FetchArgsType) => Promise<unknown>
