import { configureStore } from '@reduxjs/toolkit'
import {
  errors,
  loaders,
  permissions,
  sorters,
  brands,
  filters,
  modals,
  sourceReference,
} from 'redux/reducers'
import createSagaMiddleware from 'redux-saga'
import rootSaga from 'redux/sagas'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: {
    errors: errors.reducer,
    loaders: loaders.reducer,
    permissions: permissions.reducer,
    sorters: sorters.reducer,
    brands: brands.reducer,
    filters: filters.reducer,
    modals: modals.reducer,
    sourceReference: sourceReference.reducer,
  },
  middleware: [sagaMiddleware],
})

sagaMiddleware.run(rootSaga)

export default store
