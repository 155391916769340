import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Form, Row, Col, FormInstance, Input } from 'antd'
import { ModalsType } from 'redux/reducers/modals/types'
import { Rule } from 'rc-field-form/lib/interface'
import {
  FormRawItemsConfig,
  SourceReferenceFormItemType,
  FormItemType,
  FormRawItemType,
} from 'components/SourceReference/SourceReferenceForm/constants'
import {
  mappedSourceReferenceFormFields,
  getSourceReferenceIds,
} from 'redux/selectors/sourcereference'
import { SourceReferenceDataColumnsIds } from '../SourceReferenceTable/types'
export interface Props {
  modalId: ModalsType
  form: FormInstance<any>
}

export const SourceReferenceForm: React.FC<Props> = ({ modalId, form }) => {
  const { formatMessage } = useIntl()
  const mappedSourceReference = useSelector(mappedSourceReferenceFormFields)

  const sourceReferenceIds = useSelector(getSourceReferenceIds)

  const isUniqueId = (_: any, value: string): Promise<void> => {
    if (!value || sourceReferenceIds.includes(Number(value))) {
      return Promise.reject()
    }
    return Promise.resolve()
  }

  const mapConfigRule = useCallback(
    (id: SourceReferenceDataColumnsIds, rules: Rule[]) => {
      return rules.map((rule: any) => {
        if (id === SourceReferenceDataColumnsIds.ID) {
          rule = { ...rule, validator: isUniqueId }
        }
        const message = rule.message ? formatMessage({ id: rule.message }) : rule.message
        return {
          ...rule,
          message,
        }
      })
    },
    [formatMessage]
  )

  const renderItem = useCallback(
    ({
      id,
      type,
      config,
      col,
      onChange,
      disabled,
      excludedFrom,
      allowClear,
    }: SourceReferenceFormItemType) => {
      const rules = config
        ? mapConfigRule(id, config.rules)
        : mapConfigRule(id, [{ required: false }])
      const excluded = excludedFrom?.includes(modalId)
      const label = formatMessage({ id: `component.sourcereference.columnName.${id}` })
      return (
        !excluded && (
          <Col key={id} span={col.span} offset={col.offset}>
            <Form.Item name={id} rules={rules} label={label}>
              <Input
                key={id}
                placeholder={id}
                type={FormItemType.INPUT}
                onKeyDown={onChange}
                disabled={disabled}
                allowClear={allowClear}
              />
            </Form.Item>
          </Col>
        )
      )
    },
    [FormItemType]
  )

  const renderRawsData = useCallback(() => {
    return FormRawItemsConfig.map((row: FormRawItemType) => {
      return (
        <Row key={row.id} gutter={row.config.gutter}>
          {row.items.map((item: SourceReferenceFormItemType) => renderItem(item))}
        </Row>
      )
    })
  }, [FormRawItemsConfig])

  return (
    <Form form={form} id={modalId} fields={mappedSourceReference} layout="vertical">
      {renderRawsData()}
    </Form>
  )
}
