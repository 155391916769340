import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataTable } from 'components/common/Table/DataTable'
import { Card } from 'components/common/Card'
import { TableControls } from 'components/Permissions/TableControls'
import { fetchPermissions } from 'redux/actions/permissions'
import { fetchBrands } from 'redux/actions/brands'
import { getPermissionsItems } from 'redux/selectors/permissions'
import { getOpenedModalId } from 'redux/selectors/modals'
import { PermissionModal } from 'components/Permissions/PermissionsModal'
import {
  PERMISSIONS_TABLE_COLUMNS,
  NON_SORTABLE_COLUMNS,
} from 'components/Permissions/PermissionsTable/constants'
import { getIsLoading } from 'redux/selectors/loaders'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'

const PermissionView: React.FC = () => {
  const dispatch = useDispatch()
  const permissionData = useSelector(getPermissionsItems)
  const openedModalId = useSelector(getOpenedModalId)
  useEffect(() => {
    dispatch(fetchPermissions())
    dispatch(fetchBrands())
  }, [])

  const isLoading = useSelector<RootState, boolean>((state) =>
    getIsLoading(state, Loaders.FetchPermissions)
  )

  return (
    <>
      <PermissionModal openedModalId={openedModalId} />
      <Card isLoading={isLoading}>
        <TableControls />
        <DataTable
          data={permissionData}
          dataName="permissions"
          columns={PERMISSIONS_TABLE_COLUMNS}
          nonSortableColumns={NON_SORTABLE_COLUMNS}
        />
      </Card>
    </>
  )
}

export default PermissionView
