import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setEditPermissionById } from 'redux/actions/permissions'
import { openModal } from 'redux/actions/modals'
import { ModalsType } from 'redux/reducers/modals/types'
import { SwapOutlined } from '@ant-design/icons'
import styles from './styles.module.css'

interface StateProps {
  id: string
}

export type Props = StateProps

export const ViewButton: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch()
  const handleClick = useCallback(() => {
    dispatch(setEditPermissionById({ id: id }))
    dispatch(openModal({ id: ModalsType.ViewPermission }))
  }, [id, setEditPermissionById])

  return <SwapOutlined className={styles.wrapper} onClick={handleClick} />
}
