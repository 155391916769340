import {
  CONSUME_PERMISSIONS,
  FETCH_PERMISSIONS,
  SET_EDIT_PERMISSION_BY_ID,
  REMOVE_EDIT_PERMISSION_BY_ID,
  EDITED_PERMISSION,
  PATCH_PERMISSION,
  SET_NEW_PERMISSION,
  REMOVE_NEW_PERMISSION,
  ADD_PERMISSION,
} from 'redux/actions/permissions/constants'
import { createAction } from '@reduxjs/toolkit'
import {
  ConsumePermissionPayLoad,
  EditPermissionByIdPayload,
  EditedPermissionPayload,
  NewPermissionPayload,
} from 'redux/actions/permissions/types'

export const fetchPermissions = createAction(FETCH_PERMISSIONS)

export const consumePermissions = createAction<ConsumePermissionPayLoad>(CONSUME_PERMISSIONS)

export const setEditPermissionById =
  createAction<EditPermissionByIdPayload>(SET_EDIT_PERMISSION_BY_ID)

export const removeEditPermissionById = createAction(REMOVE_EDIT_PERMISSION_BY_ID)

export const editedPermission = createAction<EditedPermissionPayload>(EDITED_PERMISSION)

export const patchPermission = createAction(PATCH_PERMISSION)

export const setNewPermission = createAction<NewPermissionPayload>(SET_NEW_PERMISSION)

export const removeNewPermission = createAction(REMOVE_NEW_PERMISSION)

export const addPermission = createAction(ADD_PERMISSION)
