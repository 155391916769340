import React from 'react'
import { SortDirection, TableHeaderProps } from 'react-virtualized'
import classNames from 'classnames'
import { SortOptions } from 'components/common/data-display/SortOptions'
import styles from 'components/common/Table/styles.module.css'

export const HeaderRenderer: React.FC<TableHeaderProps> = ({
  label,
  dataKey,
  sortBy,
  sortDirection,
  disableSort,
}) => {
  const sortArrowsValue = dataKey === sortBy ? sortDirection || SortDirection.ASC : null
  const headerLabel = label?.toLocaleString()

  return (
    <div className={classNames(styles.headerCell, { [styles.pointer]: !disableSort })}>
      <label className={styles.headerLabel}>{headerLabel}</label>

      {!disableSort && (
        <SortOptions options={[SortDirection.ASC, SortDirection.DESC]} value={sortArrowsValue} />
      )}
    </div>
  )
}
