export enum ModalsType {
  EditPermission = 'editPermission',
  ViewPermission = 'viewPermission',
  NewPermission = 'newPermission',
  NewSourceReference = 'newSourceReference',
}

export interface ModalsStateType {
  openedModalId: ModalsType | null
}
