import { RootState } from 'redux/types/store'
import { BrandsStateType } from 'redux/reducers/brands/types'
import { createSelector } from 'reselect'
import { SelectOptionProps } from 'components/Permissions/PermissionsForm/SelectItem'

export const getBrands = (state: RootState) => state.brands

export const brandsFormSelect = createSelector(
  getBrands,
  (brands: BrandsStateType): SelectOptionProps[] => {
    return brands.map((brand) => ({ value: brand, label: brand }))
  }
)
