import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Table } from 'components/common/Table'
import { TableColumnType } from 'components/common/Table'
import {
  TableCellProps,
  defaultTableCellDataGetter,
  Column as _Column,
  ColumnProps,
} from 'react-virtualized'
import { PermissionItem } from 'redux/reducers/permissions/types'
import { SourceReferenceItem } from 'redux/reducers/sourcereference/types'
import { setSorters } from 'redux/actions/sorters'
import { getSorters } from 'redux/selectors/sorters'
import { HeaderRenderer } from 'components/common/data-display/HeaderRenderer'
import styles from 'components/common/Table/styles.module.css'

interface Props {
  data: PermissionItem[] | SourceReferenceItem[] | any[]
  dataName: string
  columns: TableColumnType[]
  nonSortableColumns: string[]
}

const Column = _Column as unknown as React.FC<ColumnProps>

export const DataTable: React.FC<Props> = ({ data, dataName, columns, nonSortableColumns }) => {
  const { formatMessage } = useIntl()

  const { sortBy, sortDirection } = useSelector(getSorters)

  const cellRenderer = useCallback((cellProps: TableCellProps) => {
    const tableColumn = columns.find((column: TableColumnType) => column.id === cellProps.dataKey)

    if (tableColumn?.customCellRenderer) {
      return tableColumn.customCellRenderer(cellProps)
    }
    return cellProps.cellData
  }, [])

  const shouldDisableSort = (dataKey: any): boolean => {
    return nonSortableColumns.includes(dataKey)
  }

  const renderHeaderColumn = useCallback(
    ({ id, width = 0, dataKey }: TableColumnType) => {
      const isSortDisabled = shouldDisableSort(dataKey)

      const labelString = formatMessage({ id: `component.${dataName}.columnName.${id}` })

      return (
        <Column
          key={id}
          label={labelString}
          width={width}
          maxWidth={width}
          dataKey={dataKey}
          flexGrow={1}
          className={styles.cell}
          cellDataGetter={defaultTableCellDataGetter}
          cellRenderer={cellRenderer}
          headerRenderer={HeaderRenderer}
          disableSort={isSortDisabled}
          headerClassName={styles.headerCellSizer}
        />
      )
    },
    [defaultTableCellDataGetter, cellRenderer]
  )

  return (
    <Table
      columns={columns}
      data={data}
      cellRenderer={cellRenderer}
      renderHeaderColumn={renderHeaderColumn}
      sorter={setSorters}
      sortBy={sortBy}
      sortDirection={sortDirection}
    />
  )
}
