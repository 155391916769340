import React from 'react'
import classNames from 'classnames'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import styles from 'components/Permissions/IsActivePermission/styles.module.css'

export interface Props {
  isActive: boolean
}

export const IsActivePermission: React.FC<Props> = ({ isActive }) => {
  if (isActive) {
    return <CheckOutlined className={classNames(styles.wrapper, styles.active)} />
  }
  return <CloseOutlined className={classNames(styles.wrapper, styles.inactive)} />
}
