import React from 'react'
import { BrowserRouter, Routes as ReactRoutes, Route, Navigate } from 'react-router-dom'
import { Routes } from 'routes/constants'
import { useAuth0 } from '@auth0/auth0-react'
import { Login } from 'routes/Login'
import Index from 'routes/Index'
import Permissions from 'routes/Permissions'
import SourceReference from 'routes/SourceReference'
import { TopMenu } from 'components/common/Navigation/TopMenu'
import { PrivateRoute } from 'components/common/PrivateRoute'
import 'App.css'

const App: React.FC = () => {
  const { isAuthenticated } = useAuth0()

  return (
    <BrowserRouter>
      <TopMenu />
      <ReactRoutes>
        <Route path="*" element={<Navigate to={isAuthenticated ? Routes.Index : Routes.Login} />} />
        <Route path={Routes.Login} element={<Login />} />
        <Route
          path={Routes.Index}
          element={
            <PrivateRoute>
              <Index />
            </PrivateRoute>
          }
        />
        <Route
          path={Routes.Permissions}
          element={
            <PrivateRoute>
              <Permissions />
            </PrivateRoute>
          }
        />
        <Route
          path={Routes.SourceReferece}
          element={
            <PrivateRoute>
              <SourceReference />
            </PrivateRoute>
          }
        />
      </ReactRoutes>
    </BrowserRouter>
  )
}

export default App
