import { MenuItem } from './types'
import { Routes } from 'routes/constants'

export type MenuItemsProps = {
  key: string
  linkTo: string
  defaultSelected?: boolean
}

export const topMenuItemsConfig: MenuItemsProps[] = [
  {
    key: MenuItem.PERMISSIONS,
    linkTo: Routes.Permissions,
    defaultSelected: true,
  },
  {
    key: MenuItem.SOURCEREFERENCE,
    linkTo: Routes.SourceReferece,
  },
]
