import React from 'react'
import { Button, Tooltip } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { TooltipProps } from 'antd/lib/tooltip'
import { useIntl } from 'react-intl'

export interface Props extends ButtonProps {
  onClick: () => void
  tooltipTitle: string
}

export const TooltipButton: React.FC<Props> = ({
  tooltipTitle,
  icon,
  disabled,
  loading,
  shape,
  size,
  type,
  onClick,
  className,
  children,
}) => {
  const { formatMessage } = useIntl()

  const title = formatMessage({ id: tooltipTitle })

  const buttonProps: ButtonProps = {
    icon,
    disabled,
    loading,
    shape,
    size,
    type,
    onClick,
    className,
  }

  return (
    <Tooltip title={title}>
      <Button {...buttonProps}>{children}</Button>
    </Tooltip>
  )
}
