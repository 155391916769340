import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { Select, Tooltip } from 'antd'
import { STATUS_LIST, StatusValue, StatusType } from './constants'
import styles from '../BrandSelect/styles.module.css'

const { Option } = Select

export interface Props {
  isLoading: boolean
  selectedStatus?: string
  onSelect: (value: string) => void
  className?: string
}

export const StatusSelect: React.FC<Props> = ({
  isLoading,
  selectedStatus,
  onSelect,
  className,
}) => {
  const { formatMessage } = useIntl()
  const renderOption = useCallback((status: string, index: number) => {
    return (
      <Option key={index} value={status as StatusType}>
        {status}
      </Option>
    )
  }, [])

  return (
    <Tooltip title={formatMessage({ id: 'component.filter.toolTip.statusSelect' })}>
      <div className={classNames(styles.wrapper, className)}>
        <Select
          allowClear={false}
          value={selectedStatus}
          disabled={isLoading}
          placeholder="status"
          onSelect={onSelect}
          className={styles.statusSelect}
          defaultValue={StatusValue[StatusValue.All]}
        >
          {STATUS_LIST.map(renderOption)}
        </Select>
      </div>
    </Tooltip>
  )
}
