import React, { useEffect } from 'react'
import { Routes } from 'routes/constants'
import 'App.css'

const Index: React.FC = () => {
  useEffect(() => {
    window.location.replace(`${Routes.Permissions}`)
  }, [])
  return <div className="App"></div>
}

export default Index
