import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { BrandsStateType } from 'redux/reducers/brands/types'
import { ConsumeBrandPayLoad } from 'redux/actions/brands/types'
import { BrandsInitialState } from 'redux/reducers/brands/constants'

export const brands = createSlice({
  name: ReducersType.Brands,
  initialState: BrandsInitialState,
  reducers: {
    consumeBrands: (
      state: BrandsStateType,
      { payload: { brands } }: PayloadAction<ConsumeBrandPayLoad>
    ) => brands,
  },
})
