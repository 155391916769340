export enum Loaders {
  ArticleIndexData = 'articleIndexData',
  EngagementData = 'engagementData',
  ArticleReferrerData = 'articleReferrerData',
  FetchPermissions = 'fetchPermission',
  FetchBrands = 'fetchBrands',
  EditPermission = 'editPermission',
  ViewPermission = 'viewPermission',
  PatchPermission = 'patchPermission',
  NewPermission = 'newPermission',
  FetchSoureReference = 'fetchSourceReference',
  NewSourceReference = 'newSourceReference',
}

export type LoadersStateType = Record<Loaders, boolean>
