import { useIntl } from 'react-intl'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

import {
  AutoSizer as _AutoSizer,
  Table as _Table,
  TableCellDataGetter,
  TableCellRenderer,
  AutoSizerProps,
  TableProps,
  SortDirectionType,
  TableCellProps,
} from 'react-virtualized'
import { SetSortersType } from 'redux/actions/sorters/types'
import { NoData } from 'components/common/NoData'
import { DEFAULT_HEADER_HEIGHT, DEFAULT_ROW_HEIGHT, NO_DATA_MESSAGE } from './constants'
import { SortByColumns } from 'redux/actions/sorters/types'

import styles from 'components/common/Table/styles.module.css'

//workaround for Typescript error with 'react-virtualized'
//not working properly with React 18 version
const AutoSizer = _AutoSizer as unknown as React.FC<AutoSizerProps>
const BaseTable = _Table as unknown as React.FC<TableProps>

export interface TableColumnType {
  id: string
  dataKey: string
  label?: string
  width?: number
  customCellRenderer?: (tableCellData: TableCellProps) => JSX.Element | string
}

export interface Props {
  data: unknown[]
  columns: TableColumnType[]
  height?: number
  headerHeight?: number
  rowHeight?: number
  cellDataGetter?: TableCellDataGetter
  cellRenderer?: TableCellRenderer
  renderHeaderColumn: ({ id, width, dataKey }: TableColumnType) => JSX.Element
  sorter?: ActionCreatorWithPayload<SetSortersType, string>
  sortBy?: SortByColumns | string
  sortDirection?: SortDirectionType
}

export const Table: React.FC<Props> = ({
  data,
  columns,
  height,
  rowHeight = DEFAULT_ROW_HEIGHT,
  headerHeight = DEFAULT_HEADER_HEIGHT,
  renderHeaderColumn,
  sorter,
  sortBy,
  sortDirection,
}) => {
  const dispatch = useDispatch()

  const { formatMessage } = useIntl()

  const tableHeight = useMemo(
    () => height || (data.length + 1) * rowHeight,
    [height, data.length, rowHeight]
  )

  const handleSort = useCallback(
    ({ sortBy, sortDirection }: any) => {
      sorter && dispatch(sorter({ sortBy, sortDirection }))
    },
    [sortBy, sortDirection]
  )

  const rowGetter = useCallback(({ index }: any) => data[index], [data])

  const noData = <NoData text={formatMessage({ id: NO_DATA_MESSAGE })} />

  return (
    <>
      <AutoSizer disableHeight>
        {({ width }) => (
          <BaseTable
            className={styles.wrapper}
            headerHeight={headerHeight}
            width={width + 20}
            height={tableHeight}
            tableWidth={width}
            rowHeight={rowHeight}
            rowClassName={styles.row}
            rowCount={data.length}
            rowGetter={rowGetter}
            sort={handleSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
            noRowsRenderer={() => noData}
            autoHeight={true}
          >
            {columns.map(renderHeaderColumn)}
          </BaseTable>
        )}
      </AutoSizer>
    </>
  )
}
