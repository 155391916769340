import { TableColumnType } from 'components/common/Table'
import { formatDate } from 'utils/date/index'
import { SourceReferenceDataColumnsIds } from './types'

export const SOURCE_REFERENCE_TABLE_COLUMNS: TableColumnType[] = [
  {
    id: SourceReferenceDataColumnsIds.ID,
    dataKey: SourceReferenceDataColumnsIds.ID,
    width: 100,
  },
  {
    id: SourceReferenceDataColumnsIds.SourceName,
    dataKey: SourceReferenceDataColumnsIds.SourceName,
  },
  {
    id: SourceReferenceDataColumnsIds.SupplierName,
    dataKey: SourceReferenceDataColumnsIds.SupplierName,
  },
  {
    id: SourceReferenceDataColumnsIds.CreatedAt,
    dataKey: SourceReferenceDataColumnsIds.CreatedAt,
    customCellRenderer: (data) => formatDate(data.rowData.createdAt),
  },
  {
    id: SourceReferenceDataColumnsIds.UpdatedAt,
    dataKey: SourceReferenceDataColumnsIds.UpdatedAt,
    customCellRenderer: (data) => formatDate(data.rowData.updatedAt),
  },
]

export const NON_SORTABLE_COLUMNS: SourceReferenceDataColumnsIds[] = []
