export enum ReducersType {
  Loaders = 'loaders',
  Errors = 'errors',
  Permissions = 'permissions',
  Sorters = 'sorters',
  Brands = 'brands',
  Filters = 'filters',
  Modals = 'modals',
  SourceReference = 'sourceReference',
}
