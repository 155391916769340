import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { PermissionsStateType } from 'redux/reducers/permissions/types'
import {
  ConsumePermissionPayLoad,
  EditPermissionByIdPayload,
  EditedPermissionPayload,
  NewPermissionPayload,
} from 'redux/actions/permissions/types'
import { PermissionsInitialState } from 'redux/reducers/permissions/constants'

export const permissions = createSlice({
  name: ReducersType.Permissions,
  initialState: PermissionsInitialState,
  reducers: {
    consumePermissions: (
      state: PermissionsStateType,
      { payload: { items } }: PayloadAction<ConsumePermissionPayLoad>
    ) => ({
      ...state,
      items,
    }),
    setEditPermissionById: (
      state: PermissionsStateType,
      { payload: { id } }: PayloadAction<EditPermissionByIdPayload>
    ) => {
      return {
        ...state,
        editPermissionId: id,
      }
    },
    removeEditPermissionById: (state: PermissionsStateType) => {
      return {
        ...state,
        editPermissionId: PermissionsInitialState.editPermissionId,
      }
    },
    editedPermission: (
      state: PermissionsStateType,
      { payload: { editedPermission } }: PayloadAction<EditedPermissionPayload>
    ) => ({
      ...state,
      editedPermission,
    }),
    setNewPermission: (
      state: PermissionsStateType,
      { payload: { newPermission } }: PayloadAction<NewPermissionPayload>
    ) => ({
      ...state,
      newPermission,
    }),
    removeNewPermission: (state: PermissionsStateType) => ({
      ...state,
      newPermission: PermissionsInitialState.newPermission,
    }),
  },
})
