import api from 'api'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { Loaders } from 'redux/reducers/loaders/types'
import { startLoading, stopLoading } from 'redux/actions/loaders/'
import { SourceReferenceItem } from 'redux/reducers/sourcereference/types'
import { consumeSourceReference, fetchSourceReference } from 'redux/actions/sourcereference'
import { handleError } from 'redux/actions/errors'
import {
  FETCH_SOURCEREFERENCE,
  ADD_SOURCE_REFERENCE,
} from 'redux/actions/sourcereference/constants'
import { getAddSourceReferenceData } from 'redux/actions/sourcereference'
import { BaseSourceReferenceItem } from 'redux/reducers/sourcereference/types'
import { closeModal } from 'redux/actions/modals'

function* handleFetchSourceReference() {
  yield put(startLoading(Loaders.FetchSoureReference))

  try {
    const data: SourceReferenceItem[] = yield call(api.getSourceReferences)

    if (data) {
      yield put(consumeSourceReference({ items: data }))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.FetchSoureReference))
  }
}

function* handleAddSourceReference(): Generator<unknown, any, BaseSourceReferenceItem> {
  yield put(startLoading(Loaders.NewSourceReference))
  const { id, sourceName, supplierName }: BaseSourceReferenceItem = yield select(
    getAddSourceReferenceData
  )

  try {
    yield call(api.addSourceReference, {
      body: { id, sourceName, supplierName },
    })
    yield put(fetchSourceReference())
    yield put(closeModal())
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.NewSourceReference))
  }
}

export function* sourcereference() {
  yield all([
    takeLatest(FETCH_SOURCEREFERENCE, handleFetchSourceReference),
    takeLatest(ADD_SOURCE_REFERENCE, handleAddSourceReference),
  ])
}
