import orderBy from 'lodash.orderby'
import { createSelector } from 'reselect'
import { StatusValue } from '../../../components/Permissions/StatusSelect/constants'
import { SortDirection, SortDirectionType } from 'react-virtualized'
import { RootState } from 'redux/types/store'
import { getSorters } from 'redux/selectors/sorters'
import { PermissionItem } from 'redux/reducers/permissions/types'
import { FilterStateType } from 'redux/reducers/filters/types'
import { SelectOptionProps } from 'components/Permissions/PermissionsForm/SelectItem'
import { PERMISSIONS_TABLE_COLUMNS } from 'components/Permissions/PermissionsTable/constants'
export interface Sorters {
  sortBy: string
  sortDirection: SortDirectionType
}

export const getPermissions = (state: RootState) => state.permissions.items
export const getFilters = (state: RootState) => state.filters

export const getPermissionsItems = createSelector(
  getPermissions,
  getSorters,
  getFilters,
  (permissions: PermissionItem[], sorters: Sorters, filters: FilterStateType): PermissionItem[] => {
    const { sortBy, sortDirection } = sorters
    if (!sortBy || !permissions.length) {
      return permissions
    }
    const data = permissions
      .filter(({ displayName, permissionCode, id }: PermissionItem) => {
        if (!filters.inputText) {
          return true
        }
        return (
          displayName.toLowerCase().includes(filters.inputText) ||
          permissionCode.toLowerCase().includes(filters.inputText) ||
          id.toLowerCase().includes(filters.inputText)
        )
      })
      .filter(({ brand }: PermissionItem) =>
        filters.brands?.length ? filters.brands.includes(brand) : true
      )
      .filter(({ active }: PermissionItem) =>
        filters.status != StatusValue.All ? active == filters.status : true
      )

    const directionOrderBy = SortDirection.ASC === sortDirection ? 'asc' : 'desc'
    return orderBy(data, [sortBy], [directionOrderBy]) as PermissionItem[]
  }
)

export const getEditPermissionId = (state: RootState) => {
  return state.permissions.editPermissionId
}

export const getPermissionRecordIds = (state: RootState) => {
  return state.permissions.items.map((item) => item.permissionId)
}
export const getEditPermissionData = (state: RootState) => state.permissions.editedPermission

export const getNewPermissionData = (state: RootState) => state.permissions.newPermission

export const getEditPermission = createSelector(
  getPermissionsItems,
  getEditPermissionId,
  (permissions, editPermissionId): PermissionItem | undefined =>
    permissions.find(({ id }: PermissionItem) => id === editPermissionId)
)

export const mappedEditPermission = createSelector(
  getPermissionsItems,
  getEditPermissionId,
  (permissions, editPermissionId) => {
    const editPermission: any = permissions.find(({ id }) => id === editPermissionId)
    const filterColumns = [
      'id',
      'permissionId',
      'displayName',
      'permissionCode',
      'brand',
      'channels',
      'description',
      'type',
      'displaySequence',
      'parentId',
      'active',
      'copy',
    ]

    if (!editPermission)
      return filterColumns.map((key) => ({
        name: [key],
        value: key === 'channels' ? [] : null,
      }))

    return filterColumns.map((key) => ({
      name: [key],
      value: key === 'copy' ? JSON.stringify(editPermission[key]) : editPermission[key],
    }))
  }
)

export const getPermissionIds = createSelector(
  getPermissions,
  (permissions: PermissionItem[]): SelectOptionProps[] =>
    permissions.map(({ id, permissionCode }: PermissionItem) => ({
      value: id,
      label: permissionCode,
    }))
)
