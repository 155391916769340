import { Form, Select } from 'antd'
import { useIntl } from 'react-intl'
import { Rule } from 'rc-field-form/lib/interface'

export type SelectOptionProps = {
  label: string
  value: string
}

export type Props = {
  name: string
  allowClear?: boolean
  showSearch?: boolean
  optionFilterProp?: string
  options: SelectOptionProps[] | undefined
  tokenSeparators?: string[]
  mode?: 'multiple' | 'tags'
  rules?: Rule[]
  disabled?: boolean
}

export const SelectItem: React.FC<Props> = ({
  name,
  allowClear,
  showSearch,
  optionFilterProp,
  options,
  tokenSeparators,
  mode,
  rules,
  disabled,
}) => {
  const { formatMessage } = useIntl()
  const itemLabel = formatMessage({ id: `component.permissions.columnName.${name}` })
  const filterOption = (input: string, options: any) =>
    (options?.label?.toLocaleString() || '').toLowerCase().includes(input.toLowerCase())
  return (
    <Form.Item label={itemLabel} name={name} rules={rules} key={name}>
      <Select
        placeholder={name}
        allowClear={allowClear}
        showSearch={showSearch}
        optionFilterProp={optionFilterProp}
        options={options}
        tokenSeparators={tokenSeparators}
        filterOption={filterOption}
        mode={mode}
        disabled={disabled}
      ></Select>
    </Form.Item>
  )
}
