import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { Routes } from 'routes/constants'
import { useAuth0 } from '@auth0/auth0-react'
import { Layout, Spin } from 'antd'
import { setAuthToken } from 'utils/local-storage'
import config from 'config'
import { LoadingOutlined } from '@ant-design/icons'
import styles from './styles.module.css'

interface Props {
  children: React.ReactNode
}

export const PrivateRoute: React.FC<Props> = ({ children }) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const { Content } = Layout
  const { audience, scope } = config.auth

  useEffect(() => {
    const getUserMetaData = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience,
            scope,
          },
        })
        setAuthToken(accessToken)
      } catch (e: any) {
        console.log(e.message)
      }
    }
    getUserMetaData()
  }, [getAccessTokenSilently])

  if (isLoading) {
    return (
      <Content className={styles.wrapper}>
        <Spin size="large" indicator={<LoadingOutlined />} />
      </Content>
    )
  }

  return isAuthenticated ? <>{children}</> : <Navigate to={Routes.Login} />
}
