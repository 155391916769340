import React, { useCallback } from 'react'
import classNames from 'classnames'
import { SortDirectionType } from 'react-virtualized'
import {
  OPTION_ICONS_BY_VALUE,
  SortOptionIcons,
} from 'components/common/data-display/SortOption/constanst'
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import styles from 'components/common/data-display/SortOptions/styles.module.css'

export interface Props {
  value: SortDirectionType
  isSelected?: boolean
  onSelect?: (value: SortDirectionType | null) => void
}

export const SortOption: React.FC<Props> = ({ value, isSelected, onSelect }) => {
  const handleClick = useCallback(() => {
    onSelect && onSelect(isSelected ? null : value)
  }, [value, isSelected, onSelect])

  const iconType = OPTION_ICONS_BY_VALUE[value]
  const Icon = iconType === SortOptionIcons.CaretUp ? CaretUpFilled : CaretDownFilled

  return (
    <Icon
      type={iconType}
      className={classNames(styles.icon, {
        [styles.selected]: isSelected,
      })}
      onClick={handleClick}
    />
  )
}
