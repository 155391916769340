import React from 'react'
import { createRoot } from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import store from 'redux/store'
import messages from 'i18n/en_US'
import App from './App'
import config from 'config'
import 'index.css'

const container = document.getElementById('root')!
const root = createRoot(container)

const { clientID, domain, redirectUri, audience, scope } = config.auth

root.render(
  <Provider store={store}>
    <IntlProvider locale="en" defaultLocale="en" messages={messages}>
      <Auth0Provider
        clientId={clientID}
        domain={domain}
        authorizationParams={{ redirect_uri: redirectUri, audience, scope }}
      >
        <App />
      </Auth0Provider>
    </IntlProvider>
  </Provider>
)
