import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setEditPermissionById } from 'redux/actions/permissions'
import { openModal } from 'redux/actions/modals'
import { ModalsType } from 'redux/reducers/modals/types'
import { EditFilled } from '@ant-design/icons'
import styles from './styles.module.css'

interface Props {
  id: string
}

export const EditPermissionButton: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch()
  const handleClick = useCallback(() => {
    dispatch(setEditPermissionById({ id: id }))
    dispatch(openModal({ id: ModalsType.EditPermission }))
  }, [id, setEditPermissionById])

  return <EditFilled className={styles.wrapper} onClick={handleClick} />
}
