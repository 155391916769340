import React, { useCallback } from 'react'
import { Form } from 'antd'
import { ModalsType } from 'redux/reducers/modals/types'
import { ModalContainer } from 'components/modals/ModalsContainer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { getIsLoading } from 'redux/selectors/loaders'
import { Loaders } from 'redux/reducers/loaders/types'
import {
  editedPermission,
  setNewPermission,
  removeNewPermission,
  addPermission,
  removeEditPermissionById,
} from 'redux/actions/permissions'
import { patchPermission } from 'redux/actions/permissions'
import { closeModal } from 'redux/actions/modals'
import { PermissionForm } from 'components/Permissions/PermissionsForm'

export type Props = {
  openedModalId: ModalsType | null
}

export const PermissionModal: React.FC<Props> = ({ openedModalId }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const loader =
    openedModalId == ModalsType.EditPermission ? Loaders.EditPermission : Loaders.ViewPermission
  const isLoading = useSelector<RootState, boolean>((state) => getIsLoading(state, loader))

  const onCloseModal = useCallback(() => {
    dispatch(removeEditPermissionById())
    dispatch(removeNewPermission())
    dispatch(closeModal())
  }, [closeModal, removeEditPermissionById, removeNewPermission])

  const onSubmit = useCallback(
    (formValues: any) => {
      openedModalId === ModalsType.EditPermission || openedModalId === ModalsType.ViewPermission
        ? dispatch(editedPermission({ editedPermission: formValues }))
        : dispatch(setNewPermission({ newPermission: formValues }))
    },
    [openedModalId, editedPermission, setNewPermission]
  )

  const onOK = useCallback(() => {
    switch (openedModalId) {
      case ModalsType.EditPermission:
        dispatch(patchPermission())
        dispatch(removeEditPermissionById())
        break
      case ModalsType.NewPermission:
        dispatch(addPermission())
        break
      default:
        dispatch(closeModal())
    }
  }, [openedModalId, addPermission, closeModal])

  const renderModalContent = useCallback(() => {
    if (openedModalId) {
      return <PermissionForm modalId={openedModalId} form={form} />
    }
    return null
  }, [openedModalId])

  return (
    <ModalContainer
      openedModalId={openedModalId}
      isLoading={isLoading}
      onCloseModal={onCloseModal}
      onSubmitClick={onSubmit}
      handleOk={onOK}
      renderModalContent={renderModalContent}
      form={form}
    />
  )
}
