import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Form, Row, Col } from 'antd'
import type { FormInstance } from 'antd'
import {
  getEditPermission,
  getPermissionIds,
  mappedEditPermission,
  getPermissionRecordIds,
} from 'redux/selectors/permissions'
import { brandsFormSelect } from 'redux/selectors/brands'
import { ModalsType } from 'redux/reducers/modals/types'
import { PermissionDataColumnsIds } from 'components/Permissions/PermissionsTable/types'
import { Rule } from 'rc-field-form/lib/interface'
import { mappedPemissionsType, mappedAllowedChannels, PermissionFormItemType } from './constants'
import { InputItem } from './InputItem'
import { SelectItem, SelectOptionProps } from './SelectItem'
import { CheckboxItem } from './CheckboxItem'
import { FormItemType, FormRawItemsConfig, FormRawItemType } from './constants'

export interface Props {
  modalId: ModalsType
  form: FormInstance<any>
}

export const PermissionForm: React.FC<Props> = ({ modalId, form }) => {
  const { formatMessage } = useIntl()
  const editPermission = useSelector(getEditPermission)
  const permissionsIds = useSelector(getPermissionIds)
  const brandsOptions = useSelector(brandsFormSelect)
  const permissionRecordIds = useSelector(getPermissionRecordIds)
  const mappedPermission = useSelector(mappedEditPermission)

  const isUniqueId = (_: any, value: string): Promise<void> => {
    if (editPermission?.permissionId === Number(value)) {
      return Promise.resolve()
    }

    if (!value || permissionRecordIds.includes(Number(value))) {
      return Promise.reject()
    }
    return Promise.resolve()
  }

  const mapConfigRule = useCallback(
    (id: PermissionDataColumnsIds, rules: Rule[]) => {
      return rules.map((rule: any) => {
        if (id === PermissionDataColumnsIds.PermissionId) {
          rule = { ...rule, validator: isUniqueId }
        }
        const message = rule.message ? formatMessage({ id: rule.message }) : rule.message
        return {
          ...rule,
          message,
        }
      })
    },
    [formatMessage]
  )

  const getSelectOptions = useCallback(
    (id: PermissionDataColumnsIds): SelectOptionProps[] | undefined => {
      switch (id) {
        case PermissionDataColumnsIds.Brand:
          return brandsOptions
        case PermissionDataColumnsIds.AllowedChannels:
          return mappedAllowedChannels
        case PermissionDataColumnsIds.PermissionType:
          return mappedPemissionsType
        case PermissionDataColumnsIds.ParentId:
          return permissionsIds
        default:
          return undefined
      }
    },
    [brandsOptions, mappedAllowedChannels, mappedPemissionsType, permissionsIds]
  )

  const renderItem = useCallback(
    ({
      id,
      type,
      config,
      col,
      mode,
      autosize,
      onChange,
      disabled,
      excludedFrom,
      allowClear,
    }: PermissionFormItemType) => {
      const rules = config
        ? mapConfigRule(id, config.rules)
        : mapConfigRule(id, [{ required: false }])
      const excluded = excludedFrom?.includes(modalId)
      switch (type) {
        case FormItemType.INPUT:
          return (
            !excluded && (
              <Col key={id} span={col.span} offset={col.offset}>
                <InputItem
                  name={id}
                  rules={rules}
                  onKeyDown={onChange}
                  disabled={
                    disabled ||
                    //disable permission Code in edit form
                    (modalId === ModalsType.EditPermission &&
                      id === PermissionDataColumnsIds.PermisionCode)
                  }
                />
              </Col>
            )
          )
        case FormItemType.CHECKBOX:
          return (
            !excluded && (
              <Col key={id} span={col.span} offset={col.offset}>
                <CheckboxItem
                  valuePropName="checked"
                  name={id}
                  defaultChecked={!!editPermission?.active}
                  disabled={disabled}
                  rules={rules}
                />
              </Col>
            )
          )
        case FormItemType.SELECT:
          return (
            !excluded && (
              <Col key={id} span={col.span} offset={col.offset}>
                <SelectItem
                  name={id}
                  options={getSelectOptions(id)}
                  mode={mode}
                  disabled={disabled}
                  rules={rules}
                  allowClear={allowClear}
                />
              </Col>
            )
          )
        case FormItemType.TEXTAREA:
          return (
            <Col key={id} span={col.span} offset={col.offset}>
              <InputItem
                name={id}
                rules={config && config?.rules}
                type={FormItemType.TEXTAREA}
                autoSize={autosize}
              />
            </Col>
          )
        default:
          return null
      }
    },
    [FormItemType]
  )

  const renderRawsData = useCallback(() => {
    return FormRawItemsConfig.map((row: FormRawItemType) => {
      return (
        <Row key={row.id} gutter={row.config.gutter}>
          {row.items.map((item: PermissionFormItemType) => renderItem(item))}
        </Row>
      )
    })
  }, [FormRawItemsConfig])

  return (
    <Form
      form={form}
      id={modalId}
      fields={mappedPermission}
      layout="vertical"
      disabled={modalId === ModalsType.ViewPermission}
    >
      {renderRawsData()}
    </Form>
  )
}
