import React, { useCallback } from 'react'
import { Form } from 'antd'
import { ModalsType } from 'redux/reducers/modals/types'
import { ModalContainer } from 'components/modals/ModalsContainer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { getIsLoading } from 'redux/selectors/loaders'
import { Loaders } from 'redux/reducers/loaders/types'
import {
  setNewSourceReference,
  removeNewSourceReference,
  addSourceReference,
} from 'redux/actions/sourcereference'
import { closeModal } from 'redux/actions/modals'
import { SourceReferenceForm } from 'components/SourceReference/SourceReferenceForm'

export type Props = {
  openedModalId: ModalsType | null
}

export const SourceReferenceModal: React.FC<Props> = ({ openedModalId }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const isLoading = useSelector<RootState, boolean>((state) =>
    getIsLoading(state, Loaders.NewSourceReference)
  )

  const onCloseModal = useCallback(() => {
    dispatch(removeNewSourceReference())
    dispatch(closeModal())
  }, [closeModal, removeNewSourceReference])

  const onSubmit = (formValues: any) => {
    dispatch(setNewSourceReference({ newSourceReference: formValues }))
  }

  const onOK = useCallback(() => {
    dispatch(addSourceReference())
    dispatch(closeModal())
    dispatch(removeNewSourceReference())
  }, [addSourceReference, closeModal, removeNewSourceReference])

  const renderModalContent = useCallback(() => {
    if (openedModalId) {
      return <SourceReferenceForm modalId={openedModalId} form={form} />
    }
    return null
  }, [openedModalId])

  return (
    <ModalContainer
      openedModalId={openedModalId}
      isLoading={isLoading}
      onCloseModal={onCloseModal}
      onSubmitClick={onSubmit}
      handleOk={onOK}
      renderModalContent={renderModalContent}
      form={form}
    />
  )
}
