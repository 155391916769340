import home from 'i18n/en_US/home'
import permissions from 'i18n/en_US/permissions'
import filter from 'i18n/en_US/filter'
import modals from 'i18n/en_US/modals'
import form from 'i18n/en_US/form'
import button from 'i18n/en_US/button'
import menu from 'i18n/en_US/menu'
import sourcereference from 'i18n/en_US/sourcereference'

const messages = {
  ...home,
  ...permissions,
  ...filter,
  ...modals,
  ...form,
  ...button,
  ...menu,
  ...sourcereference,
}

export default messages
