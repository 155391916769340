import { all, call, put, takeLatest } from 'redux-saga/effects'
import { Loaders } from 'redux/reducers/loaders/types'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { consumeBrands } from 'redux/actions/brands'
import { handleError } from 'redux/actions/errors'
import { FETCH_BRANDS } from 'redux/actions/brands/constants'
import api from 'api'

function* handleFetchBrands() {
  yield put(startLoading(Loaders.FetchBrands))

  try {
    const data: string[] = yield call(api.getBrands)

    if (data) {
      yield put(consumeBrands({ brands: data }))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.FetchBrands))
  }
}

export function* brands() {
  yield all([takeLatest(FETCH_BRANDS, handleFetchBrands)])
}
