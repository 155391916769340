import { Checkbox, Form } from 'antd'
import { useIntl } from 'react-intl'
import { Rule } from 'rc-field-form/lib/interface'

export type Props = {
  name: string
  valuePropName: string
  defaultChecked?: boolean
  disabled?: boolean
  rules?: Rule[]
}

export const CheckboxItem: React.FC<Props> = ({
  name,
  valuePropName,
  defaultChecked,
  disabled,
  rules,
}) => {
  const { formatMessage } = useIntl()
  const itemLabel = formatMessage({ id: `component.permissions.columnName.${name}` })
  return (
    <Form.Item label={itemLabel} valuePropName={valuePropName} name={name} rules={rules}>
      <Checkbox name={name} defaultChecked={defaultChecked} disabled={disabled} />
    </Form.Item>
  )
}
