const messages = {
  'component.permissions.columnName.id': 'ID',
  'component.permissions.columnName.permissionId': 'Record ID',
  'component.permissions.columnName.displayName': 'Name',
  'component.permissions.columnName.permissionCode': 'Code',
  'component.permissions.columnName.brand': 'Brand',
  'component.permissions.columnName.channels': 'Channels',
  'component.permissions.columnName.description': 'Description',
  'component.permissions.columnName.type': 'Type',
  'component.permissions.columnName.createdAt': 'Created At',
  'component.permissions.columnName.updatedAt': 'Updated At',
  'component.permissions.columnName.active': 'Active',
  'component.permissions.columnName.edit': 'Edit',
  'component.permissions.columnName.view': 'View',
  'component.permissions.columnName.copy': 'Copy',
  'component.permissions.columnName.parentId': 'Parent',
  'component.permissions.columnName.displaySequence': 'Sequence',
  'component.permissions.noData': 'No Permissions To Display',
}

export default messages
