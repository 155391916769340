import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
import { TooltipButton } from 'components/common/TooltipButton'
import { ModalsType } from 'redux/reducers/modals/types'
import { openModal } from 'redux/actions/modals/index'
import styles from './styles.module.css'

export const CreateSourceReferenceBtn: React.FC = () => {
  const dispatch = useDispatch()
  const btnTooltip = 'component.addsourcereferencebtn.tooltipTitle'

  const handleCreateBtnClick = useCallback(() => {
    dispatch(openModal({ id: ModalsType.NewSourceReference }))
  }, [])

  return (
    <div className={styles.wrapper}>
      <TooltipButton
        onClick={handleCreateBtnClick}
        tooltipTitle={btnTooltip}
        type="primary"
        icon={<PlusOutlined />}
        size="large"
        shape="circle"
      />
    </div>
  )
}
