import React from 'react'
import classNames from 'classnames'
import { Empty } from 'antd'
import styles from './styles.module.css'

export interface Props {
  text?: string
  className?: string
}

export const NoData: React.FC<Props> = ({ text }) => (
  <Empty className={classNames(styles.wrapper)} description={text} />
)
