import { ReducersType } from 'redux/reducers/types'

export const FETCH_PERMISSIONS = `${ReducersType.Permissions}/fetchPermissions`
export const CONSUME_PERMISSIONS = `${ReducersType.Permissions}/consumePermissions`
export const SET_EDIT_PERMISSION_BY_ID = `${ReducersType.Permissions}/setEditPermissionById`
export const REMOVE_EDIT_PERMISSION_BY_ID = `${ReducersType.Permissions}/removeEditPermissionById`
export const EDITED_PERMISSION = `${ReducersType.Permissions}/editedPermission`
export const PATCH_PERMISSION = `${ReducersType.Permissions}/patchPermission`
export const SET_NEW_PERMISSION = `${ReducersType.Permissions}/setNewPermission`
export const REMOVE_NEW_PERMISSION = `${ReducersType.Permissions}/removeNewPermission`
export const ADD_PERMISSION = `${ReducersType.Permissions}/addPermission`
