import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { ModalsStateType } from 'redux/reducers/modals/types'
import { ToggleModalPayload } from 'redux/actions/modals/types'
import { ModalInitialState } from 'redux/reducers/modals/constants'

export const modals = createSlice({
  name: ReducersType.Modals,
  initialState: ModalInitialState,
  reducers: {
    openModal: (
      state: ModalsStateType,
      { payload: { id } }: PayloadAction<ToggleModalPayload>
    ) => ({
      ...state,
      openedModalId: id,
    }),
    closeModal: (state: ModalsStateType) => ({
      ...state,
      openedModalId: ModalInitialState.openedModalId,
    }),
  },
})
