import {
  PermissionsStateType,
  EditedPermissionItem,
  BasePermissionItem,
} from 'redux/reducers/permissions/types'

export const PermissionsInitialState: PermissionsStateType = {
  items: [],
  editPermissionId: '',
  editedPermission: {} as EditedPermissionItem,
  newPermission: {} as BasePermissionItem,
}
