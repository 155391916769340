import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { Menu, MenuProps } from 'antd'
import { Link } from 'react-router-dom'
import { topMenuItemsConfig, MenuItemsProps } from './constants'
import styles from './styles.module.css'

export const TopMenu: React.FC = () => {
  const { formatMessage } = useIntl()
  const [current, setCurrent] = useState(window.location.pathname.slice(1))

  const mapMenuItems = useCallback((): MenuProps['items'] => {
    return topMenuItemsConfig.map((item: MenuItemsProps) => ({
      key: item.key,
      label: (
        <Link to={item.linkTo}>{formatMessage({ id: `component.topmenu.items.${item.key}` })}</Link>
      ),
    }))
  }, [topMenuItemsConfig])

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key)
  }
  const items = mapMenuItems()

  return (
    <Menu
      items={items}
      mode="horizontal"
      className={styles.menuContainer}
      onClick={onClick}
      selectedKeys={[current]}
    />
  )
}
