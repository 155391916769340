import { config } from 'config/config'
import { Environments } from 'types'

const defaultConfig = config.dev
// Use REACT_APP_TARGET_ENV (see Dockerfile)
const env: string = process.env.REACT_APP_TARGET_ENV || 'dev'

const envConfig = config[env as Environments]

const mergedConfig = {
  env,
  ...defaultConfig,
  ...envConfig,
}

export default mergedConfig
