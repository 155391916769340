import {
  FETCH_SOURCEREFERENCE,
  CONSUME_SOURCEREFERENCE,
  SET_NEW_SOURCE_REFERENCE,
  REMOVE_NEW_SOURCE_REFERENCE,
  ADD_SOURCE_REFERENCE,
} from 'redux/actions/sourcereference/constants'
import { RootState } from 'redux/types/store'
import { createAction } from '@reduxjs/toolkit'
import {
  ConsumeSourceReferencePyaLoad,
  NewSourceReferencePayload,
} from 'redux/actions/sourcereference/types'
import { BaseSourceReferenceItem } from 'redux/reducers/sourcereference/types'

export const fetchSourceReference = createAction(FETCH_SOURCEREFERENCE)

export const getAddSourceReferenceData = (state: RootState): BaseSourceReferenceItem =>
  state.sourceReference.newSourceReference

export const consumeSourceReference =
  createAction<ConsumeSourceReferencePyaLoad>(CONSUME_SOURCEREFERENCE)

export const setNewSourceReference =
  createAction<NewSourceReferencePayload>(SET_NEW_SOURCE_REFERENCE)

export const removeNewSourceReference = createAction(REMOVE_NEW_SOURCE_REFERENCE)

export const addSourceReference = createAction(ADD_SOURCE_REFERENCE)
