import {
  ApiRoutesType,
  ApiRouteNames,
  ApiRoutePaths,
  HttpContentTypeHeaderValues,
  HTTPHeadersType,
  HTTPMethods,
} from 'api/types'

const ApiRoutes: ApiRoutesType = {
  [ApiRouteNames.GetPermissions]: {
    url: ApiRoutePaths.AdminPermissions,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    },
  },
  [ApiRouteNames.GetBrands]: {
    url: ApiRoutePaths.AdminBrands,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    },
  },
  [ApiRouteNames.PatchPermission]: {
    url: ApiRoutePaths.AdminEditPermission,
    method: HTTPMethods.PATCH,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    },
  },
  [ApiRouteNames.AddPermission]: {
    url: ApiRoutePaths.AdminAddPermission,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    },
  },
  [ApiRouteNames.GetSourceReferences]: {
    url: ApiRoutePaths.AdminSourceReference,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    },
  },
  [ApiRouteNames.AddSourceRefere]: {
    url: ApiRoutePaths.AdminAddSourceReference,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    },
  },
}

export default ApiRoutes
