import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { TooltipButton } from 'components/common/TooltipButton'
import { FilterPermissions } from 'components/Permissions/FilterPermissions'
import { PlusOutlined } from '@ant-design/icons'
import { ModalsType } from 'redux/reducers/modals/types'
import { openModal } from 'redux/actions/modals'
import styles from './styles.module.css'

export const TableControls: React.FC = () => {
  const dispatch = useDispatch()

  const handleCreateBtnClick = () => {
    dispatch(openModal({ id: ModalsType.NewPermission }))
  }

  const CreatePermissionBtn = useCallback(() => {
    return (
      <TooltipButton
        onClick={handleCreateBtnClick}
        tooltipTitle="component.addpermissionbtn.tooltipTitle"
        type="primary"
        icon={<PlusOutlined />}
        size="large"
        shape="circle"
        className={styles.btn}
      />
    )
  }, [handleCreateBtnClick])

  return (
    <div className={styles.wrapper}>
      <FilterPermissions />
      <CreatePermissionBtn />
    </div>
  )
}
