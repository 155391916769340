import React from 'react'
import styles from './styles.module.css'
export interface PermissionTypeProps {
  label: string
}

type Props = PermissionTypeProps

export const PermissionType: React.FC<Props> = ({ label }) => {
  return <div className={styles.center}>{label.charAt(0).toUpperCase()}</div>
}
