import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { Space, Button } from 'antd'
import { BrandSelect } from 'components/Permissions/BrandSelect'
import { StatusSelect } from 'components/Permissions/StatusSelect'
import { SearchBar } from 'components/common/SearchBar'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { getIsLoading } from 'redux/selectors/loaders'
import { getBrands } from 'redux/selectors/brands'
import { consumeFilters } from 'redux/actions/filters'
import { StatusValue, StatusType } from '../StatusSelect/constants'
import styles from './styles.module.css'

export const FilterPermissions: React.FC = () => {
  const brands = useSelector(getBrands)

  const isLoading = useSelector<RootState, boolean>((state) =>
    getIsLoading(state, Loaders.FetchBrands)
  )

  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [inputText, setInputText] = useState('')
  const [selectedBrands, setSelectedBrands] = useState<string[]>([])
  const [status, setStatus] = useState<number>(StatusValue.All)

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value.toLowerCase())
  }

  const handleClearInputText = useCallback(() => {
    dispatch(consumeFilters({ filters: { inputText: '' } }))
  }, [dispatch])

  const handleBrandSelect = (selectedValues: string[]) => {
    setSelectedBrands(selectedValues)
  }

  const handleStatusSelect = (status: string) => {
    setStatus(StatusValue[status as StatusType])
  }

  const onFilter = useCallback(() => {
    dispatch(consumeFilters({ filters: { inputText, brands: selectedBrands, status } }))
  }, [inputText, selectedBrands, status])

  return (
    <Space align="center" className={styles.wrapper}>
      <SearchBar
        autoFocus={true}
        placeholder="search"
        value={inputText}
        onSearchTextChange={handleSearchTextChange}
        onClearTextClick={handleClearInputText}
        tooltipTitle={formatMessage({ id: 'component.filter.toolTip.textInput' })}
      />
      <BrandSelect
        isLoading={isLoading}
        brands={brands}
        selectedBrands={selectedBrands}
        onChange={handleBrandSelect}
      />
      <StatusSelect isLoading={isLoading} onSelect={handleStatusSelect} className={styles.status} />
      <Button type="primary" onClick={onFilter} className={styles.items}>
        Filter
      </Button>
    </Space>
  )
}
