import React from 'react'
import styles from './styles.module.css'

export interface Props {
  allowedChannels?: string[]
}

export const AllowedChannels: React.FC<Props> = ({ allowedChannels }) => {
  const renderData = allowedChannels ? allowedChannels.join(', ') : ''

  return <div className={styles.center}>{renderData}</div>
}
