import { KeyboardEvent } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input } from 'antd'
import { Rule } from 'rc-field-form/lib/interface'
import { FormItemType } from 'components/Permissions/PermissionsForm/constants'

export type Props = {
  name: string
  rules?: Rule[]
  disabled?: boolean
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => any | undefined
  type?: 'textarea' | undefined
  autoSize?: boolean
}

export const InputItem: React.FC<Props> = ({
  name,
  rules,
  disabled,
  onKeyDown,
  type,
  autoSize,
}) => {
  const { formatMessage } = useIntl()
  const itemLabel = formatMessage({ id: `component.permissions.columnName.${name}` })
  const inputElement =
    type === FormItemType.TEXTAREA ? (
      <Input.TextArea key={name} placeholder={name} disabled={disabled} autoSize={autoSize} />
    ) : (
      <Input key={name} placeholder={name} disabled={disabled} onKeyDown={onKeyDown} />
    )
  return (
    <Form.Item label={itemLabel} rules={rules} name={name}>
      {inputElement}
    </Form.Item>
  )
}
